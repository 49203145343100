import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import "./index.scss";

import BootLogo from "./assets/boot.png";
import MenuIcon from "./assets/menu-icon.png";

const MENU_ITEMS = [
  {
    name: "Line Up",
    href: "/line-up",
  },
  {
    name: "Programme",
    href: "/programme",
  },
  {
    name: "Tickets",
    href: "/tickets",
  },
  {
    name: "Supporters",
    href: "/supporters",
  },
  {
    name: "Food & Drink",
    href: "/page/cl1dy033xw6l10bujrplbni22",
  },
  {
    name: "Contact",
    href: "/contact-us",
  },
];

const Nav = () => {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();

  return (
    <>
      <nav className="navbar">
        <a href="/" className="nav-links nav-brand">
          <img src={BootLogo} alt="Raunds Music Festival Logo" />
          <span>Raunds Music Festival</span>
        </a>
        <span
          className="navbar-toggle"
          id="js-navbar-toggle"
          onClick={() => setOpen(!open)}
        >
          <img src={MenuIcon} alt="menu icon" />
        </span>
        <ul className={open ? `main-nav active mobile-nav` : "main-nav"}>
          {MENU_ITEMS.map(({ name, href }, index) => (
            <li key={index}>
              <a
                href={href}
                className={href === pathname ? "nav-item selected" : "nav-item"}
              >
                {name}
              </a>
            </li>
          ))}
        </ul>
      </nav>
      <div className="banner">
        <p>Get your tickets for Granny's Attic!</p>
        <p>
          <a href="/tickets">Click here</a> or phone our ticket hotline on{" "}
          <a href="tel:07756 015615">07756 015615</a> to book tickets!
        </p>
      </div>
    </>
  );
};

export default Nav;
