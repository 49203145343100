import React from "react";

import "./index.scss";

import LeftBootPrint from "./assets/left-boot-print.png";
import RightBootPrint from "./assets/right-boot-print.png";

const BootPrints = () => (
  <div className="boot-print-wrapper">
    <div id="footGroup1">
      <img src={LeftBootPrint} alt="Left boot print" />
      <img src={RightBootPrint} alt="Right boot print" />
    </div>
    <div id="footGroup2">
      <img src={LeftBootPrint} alt="Left boot print" />
      <img src={RightBootPrint} alt="Right boot print" />
    </div>
  </div>
);

export default BootPrints;
