import { gql } from "@apollo/client";

export default gql`
  query GetCustomPages {
    pages {
      id
      tinyTitle
    }
  }
`;
