import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Cookies from "js-cookie";
import { CookieBanner } from "@palmabit/react-cookie-law";
import ReactGA from "react-ga";
import Nav from "./common/components/nav";
import Footer from "./common/components/footer";
import BootPrints from "./common/components/boot-prints";

import "./styles/general.scss";

const Homepage = lazy(() => import("./pages/home"));
const OurStoryPage = lazy(() => import("./pages/our-story"));
const LineUpPage = lazy(() => import("./pages/line-up"));
const ArtistPage = lazy(() => import("./pages/artist"));
const TicketsPage = lazy(() => import("./pages/tickets"));
const ProgrammePage = lazy(() => import("./pages/programme"));
const SupportersPage = lazy(() => import("./pages/supporters"));
const ContactUsPage = lazy(() => import("./pages/contact-us"));
const VenuesPage = lazy(() => import("./pages/venues"));
const AccommodationPage = lazy(() => import("./pages/accommodation"));
const GalleryPage = lazy(() => import("./pages/gallery"));
const NewsPage = lazy(() => import("./pages/news"));
const NotFoundPage = lazy(() => import("./pages/not-found"));
const Page = lazy(() => import("./pages/page"));

const NO_BOOT_PATHS = ["/our-story", "/page/"];

const App = () => {
  const displayBoots = !NO_BOOT_PATHS.find((path) =>
    window.location.pathname.includes(path)
  );
  const [canTrackUser, setCanTrackUser] = useState(false);
  const [isGaInitialised, setIsGaInitialised] = useState(false);

  useEffect(() => {
    // Are we allowed to track the user?
    if (
      canTrackUser ||
      (Cookies.get("rcl_consent_given") &&
        Cookies.get("rcl_preferences_consent") &&
        Cookies.get("rcl_statistics_consent"))
    ) {
      if (!canTrackUser) setCanTrackUser(true); // avoids needing to read cookies again

      // Only init GA once
      if (!isGaInitialised) {
        ReactGA.initialize("UA-129736657-1");
        setIsGaInitialised(true);
        return;
      }

      // Track the locaton in GA
      ReactGA.pageview(window.location.pathname);
    }
  }, [canTrackUser, isGaInitialised]);

  return (
    <>
      <CookieBanner
        message="We use cookies to understand your site usage. That way we can tailor the site navigation, content and marketing to your specific needs."
        wholeDomain={true}
      />
      <Router>
        <Suspense fallback={<FallbackComponent />}>
          <Nav />
          <div className="page-wrapper">
            <>
              {displayBoots && <BootPrints />}
              <Routes>
                <Route exact path="/" element={<Homepage />} />
                <Route path="/our-story/*" element={<OurStoryPage />} />
                <Route path="/line-up/*" element={<LineUpPage />} />
                <Route path="/artist/:artistId" element={<ArtistPage />} />
                <Route path="/tickets" element={<TicketsPage />} />
                <Route path="/programme/*" element={<ProgrammePage />} />
                <Route path="/supporters/*" element={<SupportersPage />} />
                <Route path="/contact-us/*" element={<ContactUsPage />} />
                <Route path="/venues/*" element={<VenuesPage />} />
                <Route
                  path="/accommodation/*"
                  element={<AccommodationPage />}
                />
                <Route path="/gallery/*" element={<GalleryPage />} />
                <Route path="/news/*" element={<NewsPage />} />
                <Route path="/page/:pageId" element={<Page />} />
                <Route element={<NotFoundPage />} />
              </Routes>
            </>
          </div>
          <Footer />
        </Suspense>
      </Router>
    </>
  );
};

const FallbackComponent = () => (
  <>
    <Nav />
    <div className="page-wrapper" />
    <Footer />
  </>
);

export default App;
